import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Header from './Components/Header.jsx';
import Home from './Components/Home.jsx';
import Gallery from './Components/Gallery.jsx';
import Footer from './Components/Footer.jsx';

function App() {
	return (
		<Router>
			<div id="page-container">
				{' '}
				{/* Wrapper for sticky footer */}
				<Header />
				<div className="main">
					{' '}
					<Routes>
						<Route exact path="/" element={<Home />} />
						<Route path="/gallery" element={<Gallery />} />
					</Routes>
				</div>
				<Footer />
			</div>
		</Router>
	);
}

export default App;
