import React from 'react';

function Gallery() {
	return (
		<section id="gallery">
			<h2>Artwork Gallery</h2>
			<p>
				Display Rose Fisher's artwork here. Consider using a grid layout for the
				artwork images.
			</p>
			{/* Image grid or gallery component will go here */}
		</section>
	);
}

export default Gallery;
